import _events from "events";
import _util from "util";
import _trie from "./trie";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var EventEmitter = _events.EventEmitter,
    util = _util,
    Trie = _trie.Trie;
exports = exports = Autocomplete;
exports.version = "0.0.1";

exports.connectAutocomplete = function (getInitialElements) {
  Autocomplete.singleton = new Autocomplete();
  return Autocomplete.singleton;
};

function Autocomplete(name) {
  (this || _global).trie = new Trie();
  EventEmitter.call(this || _global);
}

util.inherits(Autocomplete, EventEmitter);

Autocomplete.prototype.close = function () {
  this.emit("close");
};

Autocomplete.prototype.initialize = function (getInitialElements) {
  getInitialElements(function (elements) {
    elements.forEach(function (element) {
      Autocomplete.singleton.addElement(element);
    });
    Autocomplete.singleton.emit("loaded");
  });
};

Autocomplete.prototype.addElement = function (element) {
  (this || _global).trie.addValue(element);
};

Autocomplete.prototype.removeElement = function (element) {
  (this || _global).trie.removeValue(element);
};

Autocomplete.prototype.search = function (prefix) {
  return (this || _global).trie.autoComplete(prefix);
};

export default exports;